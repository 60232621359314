export const BrandLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="16"
    viewBox="0 0 60 16"
    fill="none"
  >
    <mask
      id="mask0_68_695"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="60"
      height="16"
    >
      <path d="M59.2594 0H0V16H59.2594V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_68_695)">
      <path
        d="M38.9371 3.37671H35.8457V15.7802H38.9371V3.37671Z"
        fill="#6E6E6E"
      />
      <path
        d="M13.9359 15.9786C10.1689 15.9786 7.39893 13.3869 7.39893 9.60322C7.39893 5.81029 10.1515 3.20288 13.9359 3.20288C17.7449 3.20288 20.4725 5.78467 20.4725 9.60322C20.4725 13.4002 17.7158 15.9786 13.9359 15.9786ZM11.5133 12.1526C12.7941 13.4894 15.091 13.4723 16.3684 12.1399C17.6595 10.7932 17.6527 8.37158 16.3752 7.02074C15.106 5.67913 12.7777 5.66274 11.5047 7.00879C10.2167 8.37021 10.2116 10.7952 11.5133 12.1526Z"
        fill="#6E6E6E"
      />
      <path
        d="M27.882 3.32931C28.8964 3.11277 30.1086 3.16469 31.0717 3.54756C32.2979 4.03462 33.0404 5.00087 33.4083 6.23967C33.5996 6.88384 33.7017 7.63867 33.7017 8.51168V15.7802H30.6103V9.23098C30.6103 8.07517 30.4409 6.60889 29.1748 6.16078C28.4906 5.91862 27.5281 5.95892 26.8635 6.24172C26.0639 6.58191 25.5683 7.2493 25.3193 8.06219C25.1871 8.4946 25.1168 8.99326 25.1168 9.55375V15.7802H22.0254V3.37679H25.1168V4.85434C25.8631 4.07834 26.8259 3.55474 27.882 3.32931Z"
        fill="#6E6E6E"
      />
      <path
        d="M42.8647 4.64384C45.623 2.33666 50.1903 2.86572 52.4476 5.62614C51.7423 6.23615 51.0356 6.8448 50.3296 7.45481C49.2196 6.14906 47.4958 5.61999 45.8731 6.25528C43.0884 7.34517 43.1031 11.8161 45.8727 12.9012C47.4367 13.5136 49.2326 13.017 50.3604 11.8052C51.0745 12.4309 51.7863 13.059 52.5002 13.684C49.9013 16.6504 44.8101 16.7969 42.2318 13.9491C39.8887 11.3615 40.1787 6.88988 42.8647 4.64384Z"
        fill="#6E6E6E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.09831 3.3969H6.19687V6.49545H3.09831V12.6929H6.19687V15.7918H3.09831V12.6929H-0.000244141V0.29834H3.09831V3.3969Z"
        fill="#6E6E6E"
      />
      <path
        d="M53.8354 3.14797H54.4646V0.555829H55.3376V0.00195312H52.9758V0.555829H53.8354V3.14797Z"
        fill="#6E6E6E"
      />
      <path
        d="M56.0684 3.14797H56.6887V1.19832H56.6976L57.4597 2.55421H57.8629L58.6295 1.20276H58.6383V3.14797H59.2587V0.00195312H58.6339L57.668 1.79208H57.6591L56.6931 0.00195312H56.0684V3.14797Z"
        fill="#6E6E6E"
      />
    </g>
  </svg>
);

export const GridSize = () => (
  <svg
    width="86"
    height="25"
    viewBox="0 0 86 25"
    fill="none"
  >
    <rect opacity="0.4" width="20" height="11.25" rx="4" fill="#A9A9A9" />
    <rect
      opacity="0.4"
      x="22"
      width="20"
      height="11.25"
      rx="4"
      fill="#A9A9A9"
    />
    <rect
      opacity="0.4"
      y="13.25"
      width="20"
      height="11.25"
      rx="4"
      fill="#A9A9A9"
    />
    <rect
      opacity="0.4"
      x="22"
      y="13.25"
      width="20"
      height="11.25"
      rx="4"
      fill="#A9A9A9"
    />
    <rect
      opacity="0.4"
      x="44"
      width="20"
      height="11.25"
      rx="4"
      fill="#A9A9A9"
    />
    <rect
      opacity="0.4"
      x="66"
      width="20"
      height="11.25"
      rx="4"
      fill="#A9A9A9"
    />
    <rect
      opacity="0.4"
      x="44"
      y="13.25"
      width="20"
      height="11.25"
      rx="4"
      fill="#A9A9A9"
    />
    <rect
      opacity="0.4"
      x="66"
      y="13.25"
      width="20"
      height="11.25"
      rx="4"
      fill="#A9A9A9"
    />
  </svg>
);

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BrandLogo } from "../../svgIcons";
import "./login.scss";

const Login = () => {
  const [details, setDetails] = useState({
    name: "",
    pass: "",
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  console.log(isAuthenticated);
  const [formState, setFormState] = useState("");
  const navigate = useNavigate();

  const handleChange = (val) => {
    setDetails({ ...details, [val.target.name]: val.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (
      (details.name === "venkat" && details.pass === "Venkat@1") ||
      (details.name === "ashay" && details.pass === "Ashay@1") ||
      (details.name === "pulak" && details.pass === "Ocean@207") ||
      (details.name === "admin" && details.pass === "Admin@1")
    ) {
      sessionStorage.setItem("authenticated", "true");
      setFormState("success");
      setTimeout(() => {
        setFormState(""); // Clear the form state after 2 seconds
        navigate("/ev");
      }, 1500);
    } else {
      setFormState("error");
      setTimeout(() => {
        setFormState(""); // Clear the form state after 2 seconds
      }, 1500);
    }

    if (details.name === "admin" && details.pass === "Admin@1") {
      sessionStorage.setItem("admin", "true");
    } else {
      sessionStorage.setItem("admin", "false");
    }
  };

  useEffect(() => {
    const authStatus = sessionStorage.getItem("authenticated");
    if (authStatus === "true") {
      setIsAuthenticated(true);
      navigate("/ev");
    } else {
      setIsAuthenticated(false);
      navigate("/login");
    }
  }, [navigate]);

  return (
    <section className="login">
      <div className="container">
        <div className={`loginBox ${formState}`}>
          <form className="loginForm">
            <div className="loginFormHead">
              <BrandLogo />
              <p>angle hunting</p>
            </div>
            <div className="loginFormField">
              <input
                onChange={handleChange}
                type="text"
                placeholder="username"
                name="name"
                value={details.name}
              />
            </div>
            <div className="loginFormField">
              <input
                onChange={handleChange}
                type="password"
                placeholder="password"
                name="pass"
                value={details.pass}
              />
            </div>
            <div className="loginFormSubmit">
              <button
                disabled={
                  details.name.length > 3 && details.pass.length > 4
                    ? false
                    : true
                }
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="unsopported">
        <p>
          Please visit us again on a desktop. <br />
          Thank you
        </p>
      </div>
    </section>
  );
};

export default Login;
